/* .carousel-container {
    width: 80%;
    margin: 0 auto;
  }
  
  .carousel-item {
    text-align: center;
    padding: 20px;
  }
  
  .carousel-item img {
    width: 100%;
    max-height: 200px;
    border-radius: 8px;
  }
  
  .carousel-item h3 {
    margin-top: 10px;
    font-size: 18px;
  }
  
  .carousel-item p {
    font-size: 14px;
  }
   */

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.arrow-btn:hover {
  color: #333;
}

.cards-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.carousel-card {
  flex: 0 0 33.33%; /* Each card occupies 33.33% of the container */
  transition: transform 0.5s ease;
}

.carousel-card.active {
  transform: scale(1.2);
}

.carousel-card img {
  width: 100%;
  height: auto;
}

.carousel-card h2 {
  margin: 10px 0;
}

.carousel-card p {
  margin: 0;
}


/* cards design  */

/* footer  */

.subcribe_input {
  background: transparent;
}