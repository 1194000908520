.city_name_text {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.container {
  position: relative;
  width: 300px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
}

.image {
  position: absolute;
  width: 100%; /* Cover the entire container */
  height: 100%; /* Cover the entire container */
}

.image1 {
  z-index: 1; /* Place image1 above other images */
}

.image2,
.image3 {
  z-index: 2; /* Place image2 and image3 above image1 */
  overflow: hidden; /* Hide overflow of the images */
}

.image2 {
  top: 20px; /* Adjust position as needed */
  left: 20px; /* Adjust position as needed */
}

.image3 {
  top: 40px; /* Adjust position as needed */
  left: 40px; /* Adjust position as needed */
}